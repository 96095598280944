import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React from 'react';
import { Component } from 'react';
import './App.css';
import axios from 'axios';
import MainICBLookupTabs from './ICDCodeMain';
import { Loader } from './Loader';
import { config } from './config';
import DropDown from './DropDown';

require('./styles.css');

const environment = process.env.NODE_ENV === 'development' ? config.development : config.production;
function onRowSelect(row, isSelected) {
  console.log(row);
  console.log(`selected: ${isSelected}`);
}

function onSelectAll(isSelected) {
  console.log(`is select all: ${isSelected}`);
}

function onAfterSaveCell(row, cellName, cellValue) {
  console.log(`Save cell ${cellName} with value ${cellValue}`);
  console.log('The whole row :');
  console.log(row);
}

function onAfterTableComplete() {
  console.log('Table render complete.');
}

function onAfterDeleteRow(rowKeys) {
  console.log('onAfterDeleteRow');
  console.log(rowKeys);
}

function onAfterInsertRow(row) {
  console.log('onAfterInsertRow');
  console.log(row);
}

const selectRowProp = {
  mode: 'checkbox',
  clickToSelect: true,
  selected: [], // default select on table
  bgColor: 'rgb(238, 193, 213)',
  onSelect: onRowSelect,
  onSelectAll: onSelectAll
};

const cellEditProp = {
  mode: 'click',
  blurToSave: true,
  afterSaveCell: onAfterSaveCell
};

const options = {
  paginationShowsTotal: true,
  sortName: 'name',  // default sort column name
  sortOrder: 'desc',  // default sort order
  afterTableComplete: onAfterTableComplete, // A hook for after table render complete.
  afterDeleteRow: onAfterDeleteRow,  // A hook for after droping rows.
  afterInsertRow: onAfterInsertRow   // A hook for after insert rows
};

function trClassNameFormat(rowData, rIndex) {
  return rIndex % 3 === 0 ? 'third-tr' : '';
}

function columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
  return colIdx % 2 === 0 ? 'td-column-function-even-example' : 'td-column-function-odd-example';
}

function columnTextEmbed9(cell, row) {
  switch (row.isExcluded9) {
    case 1:
      return cell + ' ' + '<font color="red">* Invalid for No-fault claims*</font>'
    default:
      return cell
  }
}

function columnTextEmbed10(cell, row) {
  switch (row.isExcluded10) {
    case 1:
      return cell + ' ' + '<font color="red">* Invalid for No-fault claims*</font>'
    default:
      return cell
  }
}

function columnTextColor9(fieldValue, row, rowIdx, colIdx) {
  switch (row.isExcluded9) {
    case 2:
      return 'text-red'
    case 0:
      return row
    default:
      return fieldValue
  }

}

function columnTextColor10(fieldValue, row, rowIdx, colIdx) {
  switch (row.isExcluded10) {
    case 2:
      return 'text-red'
    case 0:
      return row
    default:
      return fieldValue
  }

}

function nameValidator(value) {
  if (!value) {
    return 'Job Name is required!';
  } else if (value.length < 3) {
    return 'Job Name length must great 3 char';
  }
  return true;
}
function priorityValidator(value) {
  if (!value) {
    return 'Priority is required!';
  }
  return true;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData9to10: [],
      tableData10to9: [],
      searchTextICD9: '',
      searchTextICD10: '',
      loader: false,
      selectedValue: 'ICD 9 to ICD 10',
      searchField: '',
      isSearchingICD9: false,
      isSearchingICD10: false,
      page: ''
    }
  }

  componentDidMount() {
    const url = `${environment.url}getICDCode`;
    this.setState({
      loader: true
    })
    axios.get(url, {
    })
      .then((response) => {
        if (this.state.selectedValue === 'ICD 9 to ICD 10') {
          this.setState({
            tableData9to10: response.data.recordsets,
            loader: false
          });
        } else {
          this.setState({
            tableData10to9: response.data.recordsets,
            loader: false
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  handleChangeICD9 = event => {
    const value = event.target.value;
    const trimmedValue = value.trimLeft();
    if (this.state.selectedValue === "ICD 9 to ICD 10" && this.state.isSearchingICD9 === true) {
      this.setState({
        input: trimmedValue,
        searchTextICD9: trimmedValue,
        loader: false,
        isSearchingICD9: true
      });
    } else if (this.state.selectedValue === "ICD 9 to ICD 10" && this.state.isSearchingICD9 === false) {
      this.setState({
        input: trimmedValue,
        searchTextICD9: trimmedValue,
        loader: false,
        isSearchingICD9: false
      });
    }
  }

  handleChangeICD10 = event => {
    const value = event.target.value;
    const trimmedValue = value.trimLeft();
    if (this.state.selectedValue === "ICD 10 to ICD 9" && this.state.isSearchingICD10 === true) {
      this.setState({
        input: trimmedValue,
        searchTextICD10: trimmedValue,
        loader: false,
        isSearchingICD10: true
      });
    } else if (this.state.selectedValue === "ICD 10 to ICD 9" && this.state.isSearchingICD10 === false) {
      this.setState({
        input: trimmedValue,
        searchTextICD10: trimmedValue,
        loader: false,
        isSearchingICD10: false
      });
    }
  }

  handleKeyPress = (event) => {
    const url = `${environment.url}getICDCodeData`;
    this.setState({
      loader: true
    })
    if (event.key === 'Enter' && this.state.selectedValue === 'ICD 9 to ICD 10') {
      this.handleClearButtonClick();
      axios.get(url, {
        params: {
          icdtype: 9,
          searchText: this.state.searchTextICD9,
        }
      })
        .then((response) => {
          if (response.data.recordsets.length === 0) {
            response.data.recordsets.push([]);
          }
          this.setState({
            tableData9to10: response.data.recordsets,
            loader: false,
            isSearchingICD9: true
          });
        })
        .catch(function (error) {
          console.log(error);
        })
    } else if (event.key === 'Enter' && this.state.selectedValue === 'ICD 10 to ICD 9') {
      this.handleClearButtonClick();
      axios.get(url, {
        params: {
          icdtype: 0,
          searchText: this.state.searchTextICD10,
        }
      })
        .then((response) => {
          if (response.data.recordsets.length === 0) {
            response.data.recordsets.push([]);
          }
          this.setState({
            tableData10to9: response.data.recordsets,
            loader: false,
            isSearchingICD10: true
          });
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }

  handleClickICD = (event) => {
    this.handleClearButtonClick();
    const url = `${environment.url}getICDCodeData`;
    this.setState({
      loader: true
    })
    if (this.state.selectedValue === 'ICD 9 to ICD 10') {
      axios.get(url, {
        params: {
          icdtype: 9,
          searchText: this.state.searchTextICD9,
        }
      })
        .then((response) => {
          if (response.data.recordsets.length === 0) {
            response.data.recordsets.push([]);
          }
          this.setState({
            tableData9to10: response.data.recordsets,
            loader: false,
            isSearchingICD9: true
          });
        })
        .catch(function (error) {
          console.log(error);
        })
    } else if (this.state.selectedValue === 'ICD 10 to ICD 9') {
      axios.get(url, {
        params: {
          icdtype: 0,
          searchText: this.state.searchTextICD10,
        }
      })
        .then((response) => {
          if (response.data.recordsets.length === 0) {
            response.data.recordsets.push([]);
          }
          this.setState({
            tableData10to9: response.data.recordsets,
            loader: false,
            isSearchingICD10: true
          });
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }

  selectedValue = (event) => {
    let element = document.getElementById('body');
    if (event === 'ICD 10 to ICD 9') {
      element.classList.remove('aj-t-blue')
      element.classList.add('aj-t-green')
    } else {
      element.classList.remove('aj-t-green')
      element.classList.add('aj-t-blue')
    }
    this.handleClearButtonClick();
    this.setState({
      selectedValue: event,
    })
  }

  handleReset = (event) => {
    this.handleClearButtonClick();
    this.setState({
      searchTextICD9: '',
      searchTextICD10: '',
      tableData9to10: [],
      tableData10to9: [],
      isSearchingICD9: false,
      isSearchingICD10: false
    });
  }

  handleClearButtonClick() {
    const tableGlobalSearchInputEle = document.querySelector('.react-bs-table-search-clear-btn');
    if (tableGlobalSearchInputEle) {
      tableGlobalSearchInputEle.click();
    }
  }

  render() {
    const options = {
      paginationSize: 3,
      clearSearch: true,
    }
    return (
      <div className="App">
        <div id="body" className="aj--project__hack aj-t-blue">
          <header className="clearfix">
            <div className="container">
              <div className="aj--logo">
                <h3>Spiralogics ICD Lookup & Conversion Tool </h3>
                <p className="App-intro">{this.state.apiResponse}</p>
              </div>

              <nav className="aj--nav__hack">
                {this.state.selectedValue === "ICD 9 to ICD 10"
                  ?
                  <div label="ICD 9 to ICD 10">
                    <label> ICD Code 9 Lookup:  </label>
                    <div className="aj--button__box">
                      <input type="text" name="searchICD9" value={this.state.searchTextICD9}
                        onChange={this.handleChangeICD9} id="ICD 9" onKeyPress={this.handleKeyPress} placeholder="Type your keyword to search here">
                      </input>
                      <DropDown
                        key="ICD 9 to ICD 10"
                        label="ICD 9 to ICD 10"
                        onChange={this.selectedValue}
                      />
                      <input type="submit" className="btn btn-aj__hacked" value="Search" onClick={this.handleClickICD}></input>
                      <input type="button" className="btn2 btn-aj__reset" value="Reset" onClick={this.handleReset}></input>

                      <p style={{ fontSize: '14px', float: 'left', color: 'white', marginTop: '5px' }}>NOTE: Codes that appear in <span style={{ color: 'RED' }}>RED</span> Text are invalid and excluded from SCHIP Reporting (Section 111 Reporting).</p>
                    </div>
                  </div>
                  :
                  <div label="ICD 10 to ICD 9">
                    <label> ICD Code 10 Lookup:  </label>
                    <div className="aj--button__box clearfix">
                      <input type="text" name="searchICD10" value={this.state.searchTextICD10}
                        onChange={this.handleChangeICD10} id="ICD 10" onKeyPress={this.handleKeyPress} placeholder="Type your keyword to search here">
                      </input>
                      <DropDown
                        key="ICD 10 to ICD 9"
                        label="ICD 10 to ICD 9"
                        onChange={this.selectedValue}
                      />
                      <input type="submit" className="btn btn-aj__hacked" value="Search" onClick={this.handleClickICD} ></input>
                      <input type="button" className="btn2 btn-aj__reset" value="Reset" onClick={this.handleReset}></input>
                      <p style={{ fontSize: '14px', float: 'left', color: 'white', marginTop: '5px' }}>NOTE: Codes that appear in <span style={{ color: 'RED' }}>RED</span> Text are invalid and excluded from SCHIP Reporting (Section 111 Reporting).</p>
                    </div>
                  </div>
                }
              </nav>
              {this.state.loader ? <Loader /> : null}
            </div>
          </header>
          <main className="clearfix">
            <div className="container">
              {this.state.selectedValue === 'ICD 9 to ICD 10' && this.state.tableData9to10 && this.state.isSearchingICD9 && this.state.searchTextICD9 !== "" ?
                <label> You searched for: "{this.state.searchTextICD9}". Total Count: {
                  this.state.tableData9to10.length ? this.state.tableData9to10[0].length : 0} </label> :
                this.state.selectedValue === 'ICD 10 to ICD 9' && this.state.tableData10to9 && this.state.isSearchingICD10 && this.state.searchTextICD10 !== "" ?
                  <label> You searched for: "{this.state.searchTextICD10}". Total Count: {
                    this.state.tableData10to9.length ? this.state.tableData10to9[0].length : 0} </label> :
                  null
              }
              {this.state.selectedValue === 'ICD 9 to ICD 10' && this.state.tableData9to10 && this.state.isSearchingICD9 ?
                <BootstrapTable
                  data={this.state.tableData9to10[0]}
                  trClassName={trClassNameFormat}
                  options={options}
                  search
                  searchPlaceholder='Result Filter'
                  striped
                  bordered
                  hover={true}
                  condensed
                  noDataText
                  pagination>
                  <TableHeaderColumn dataField='ICDCode9' width='5%' isKey columnClassName={columnClassNameFormat, columnTextColor9} >ICD 9</TableHeaderColumn>
                  <TableHeaderColumn dataField='ICD9Desc' width='20%' height='120px' dataFormat={columnTextEmbed9} columnClassName={columnClassNameFormat, columnTextColor9}>ICD 9 Description</TableHeaderColumn>
                  <TableHeaderColumn dataField='ICDCode10' width='5%' columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10</TableHeaderColumn>
                  <TableHeaderColumn dataField='ICD10Desc' width='15%' dataFormat={columnTextEmbed10} columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10 Description</TableHeaderColumn>
                </BootstrapTable>
                : this.state.selectedValue === 'ICD 10 to ICD 9' && this.state.tableData10to9 && this.state.isSearchingICD10 ?
                  <BootstrapTable
                    data={this.state.tableData10to9[0]}
                    trClassName={trClassNameFormat}
                    options={options}
                    search
                    searchPlaceholder='Result Filter'
                    striped
                    bordered
                    hover={true}
                    condensed
                    pagination>
                    <TableHeaderColumn dataField='ICDCode10' width='5%' columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10</TableHeaderColumn>
                    <TableHeaderColumn dataField='ICD10Desc' width='15%' dataFormat={columnTextEmbed10} columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10 Description</TableHeaderColumn>
                    <TableHeaderColumn dataField='ICDCode9' width='5%' isKey columnClassName={columnClassNameFormat, columnTextColor9}>ICD 9</TableHeaderColumn>
                    <TableHeaderColumn dataField='ICD9Desc' width='20%' dataFormat={columnTextEmbed9} columnClassName={columnClassNameFormat, columnTextColor9}>ICD 9 Description</TableHeaderColumn>
                  </BootstrapTable>
                  : this.state.selectedValue === 'ICD 9 to ICD 10' && !this.state.isSearchingICD9 ?
                    <BootstrapTable
                      data={[]}
                      trClassName={trClassNameFormat}
                      options={options}
                      search
                      searchPlaceholder='Result Filter'
                      striped
                      bordered
                      hover={true}
                      condensed
                      pagination>
                      <TableHeaderColumn dataField='ICDCode9' width='5%' isKey columnClassName={columnClassNameFormat, columnTextColor9}>ICD 9</TableHeaderColumn>
                      <TableHeaderColumn dataField='ICD9Desc' width='20%' dataFormat={columnTextEmbed9} columnClassName={columnClassNameFormat, columnTextColor9}>ICD 9 Description</TableHeaderColumn>
                      <TableHeaderColumn dataField='ICDCode10' width='5%' columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10</TableHeaderColumn>
                      <TableHeaderColumn dataField='ICD10Desc' width='15%' dataFormat={columnTextEmbed10} columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10 Description</TableHeaderColumn>
                    </BootstrapTable>
                    : this.state.selectedValue === 'ICD 10 to ICD 9' && !this.state.isSearchingICD10 ?
                      <BootstrapTable
                        data={[]}
                        trClassName={trClassNameFormat}
                        options={options}
                        search
                        searchPlaceholder='Result Filter'
                        striped
                        bordered
                        hover={true}
                        condensed
                        pagination>
                        <TableHeaderColumn dataField='ICDCode10' width='5%' columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10</TableHeaderColumn>
                        <TableHeaderColumn dataField='ICD10Desc' width='15%' dataFormat={columnTextEmbed10} columnClassName={columnClassNameFormat, columnTextColor10}>ICD 10 Description</TableHeaderColumn>
                        <TableHeaderColumn dataField='ICDCode9' width='5%' isKey columnClassName={columnClassNameFormat, columnTextColor9}>ICD 9</TableHeaderColumn>
                        <TableHeaderColumn dataField='ICD9Desc' width='20%' dataFormat={columnTextEmbed9} columnClassName={columnClassNameFormat, columnTextColor9}>ICD 9 Description</TableHeaderColumn>
                      </BootstrapTable> : null
              }
            </div>
            <p style={{ color: 'black' }}>*Codes are based on CMS’ published 2020 ICD Code.</p>
          </main>
          <footer>
            <p> Copyright © 2019 Spiralogics, Inc- All Rights Reserved.
          </p>
          </footer>
        </div>
      </div>
    );
  }
}

export default App;
