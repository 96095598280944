import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import App from './App';

class MainICBLookupTabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    selectedTab: PropTypes.func

  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    let element = document.getElementById('body');
    if (tab === 'ICD 10- ICD 9') {
      element.classList.remove('aj-t-blue')
      element.classList.add('aj-t-green')
    } else {
      element.classList.remove('aj-t-green')
      element.classList.add('aj-t-blue')
    }
    this.setState({ activeTab: tab });
    this.props.selectedTab(tab)

  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default MainICBLookupTabs;