import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Dropdown extends Component {
    constructor(props) {
        super(props);
    }
    static propTypes = {
        activeDropDown: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    onChange = (event) => {
        const { label, onChange } = this.props;
        this.setState({ label: event.target.value })
        onChange(event.target.value)
    }

    render() {
        const {
            onChange,
            props: {
                activeDropDown,
                label,
            },
        } = this;

        let className = 'tab-list-item';

        if (activeDropDown === label) {
            className += ' tab-list-active';
        }

        return (
            <select value={this.props.label} className={className} onChange={onChange}>
                <option value="ICD 9 to ICD 10" >ICD 9 to ICD 10</option>
                <option value="ICD 10 to ICD 9" >ICD 10 to ICD 9</option>
            </select>
        );
    }
}

export default Dropdown;